/* eslint-disable prettier/prettier */
import { FixedNumber } from "@ethersproject/bignumber";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { blockchainHandler } from "@/blockchain";
import moment, { Duration } from "moment";
import Web3 from "web3";
import { Zero } from "@/constants";
import { bnHelper } from "./bignumber-helper";

const DECIMALS = 18;
const SENCOND_PER_YEAR = FixedNumber.from(`${60 * 60 * 24 * 365}`);
const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

class PoolInfo {
  poolToken!: string;
  allocPoint!: FixedNumber;
  lastRewardTime!: string;
  accRewardPerShare!: string;
  amount!: FixedNumber;
  lockDuration!: Duration;
}

export class StakingHandler {
  farmContract: any;
  tokenContract: any;
  rewardToken?: string;

  tokenPoolInfo?: PoolInfo;
  lpToken?: string;
  tokenPerSecond = FixedNumber.from("0");
  totalAllocPoint = FixedNumber.from("0");

  constructor() {
    this.tokenContract = new web3.eth.Contract(require("./erc20.abi.json"), process.env.VUE_APP_TOKEN_ADDRESS);
    this.farmContract = new web3.eth.Contract(require("./farm.abi.json"), process.env.VUE_APP_FARM_ADDRESS);
  }

  async load() {
    loadingController.increaseRequest();
    try {
      const methods = this.farmContract.methods;

      const [
        rewardToken,
        tokenPerSecond,
        totalAllocPoint,
        poolLength,
        tokenPoolInfor,
        // lpPoolInfo,
      ] = await blockchainHandler.etherBatchRequest(web3, [
        methods.rewardToken(),
        methods.tokenPerSecond(),
        methods.totalAllocPoint(),
        methods.poolLength(),
        methods.poolInfo(0),
        // methods.poolInfo(1),
      ]);

      this.tokenPoolInfo = {
        poolToken: (tokenPoolInfor as any).poolToken,
        allocPoint: FixedNumber.from(`${(tokenPoolInfor as any).allocPoint}`),
        lastRewardTime: moment(`${(tokenPoolInfor as any).lastRewardTime}`, "X").toISOString(),
        amount: FixedNumber.from(`${bnHelper.fromDecimals((tokenPoolInfor as any).amount, DECIMALS)}`),
        lockDuration: moment.duration((tokenPoolInfor as any).lockDuration, "second"),
      } as PoolInfo;
      this.rewardToken = web3.utils.toChecksumAddress(`${rewardToken}`);
      this.tokenPerSecond = FixedNumber.from(`${bnHelper.fromDecimals(`${tokenPerSecond}`, DECIMALS)}`);
      this.totalAllocPoint = FixedNumber.from(`${totalAllocPoint}`);
      this.tokenContract = new web3.eth.Contract(require("./erc20.abi.json"), this.rewardToken);
    } catch (error) {
      console.error(error);
    } finally {
      loadingController.decreaseRequest();
    }
  }

  async getTokenPoolInfo() {
    const tokenPoolInfor = await this.farmContract.methods.poolInfo(0).call();
    this.tokenPoolInfo = {
      poolToken: (tokenPoolInfor as any).poolToken,
      allocPoint: FixedNumber.from(`${(tokenPoolInfor as any).allocPoint}`),
      lastRewardTime: moment(`${(tokenPoolInfor as any).lastRewardTime}`, "X").toISOString(),
      amount: FixedNumber.from(`${bnHelper.fromDecimals((tokenPoolInfor as any).amount, DECIMALS)}`),
      lockDuration: moment.duration((tokenPoolInfor as any).lockDuration, "second"),
    } as PoolInfo;
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.farmContract = new web3.eth.Contract(require("./farm.abi.json"), process.env.VUE_APP_FARM_ADDRESS);
      this.tokenContract = new web3.eth.Contract(require("./erc20.abi.json"), process.env.VUE_APP_TOKEN_ADDRESS);
    }
  }

  async approved(account) {
    try {
      const allowance = await this.tokenContract.methods.allowance(account, process.env.VUE_APP_FARM_ADDRESS).call();
      console.log(allowance)
      return allowance;
      //return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }
  async approve(account) {
    try {
      const f = this.tokenContract.methods.approve(
        process.env.VUE_APP_FARM_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.log("error", error);
    }
  }

  async stake(poolId, account, amount) {
    const f = this.farmContract.methods.stake(poolId, bnHelper.toDecimalString(amount, DECIMALS));
    await sendRequest(f, account);
  }

  async unstake(poolId, account) {
    const f = this.farmContract.methods.unstake(poolId);
    await sendRequest(f, account);
  }

  async harvest(poolId, account) {
    const f = this.farmContract.methods.harvest(poolId);
    await sendRequest(f, account);
  }

  async getUserInfo(poolId, account) {
    const { 0: amount, 1: rewardDebt, 2: rewardAmount, 3: lastStakeTime } = await this.farmContract.methods
      .userInfo(poolId, account)
      .call();
    return { amount, rewardDebt, rewardAmount, lastStakeTime: +lastStakeTime ? moment(+lastStakeTime * 1000) : null };
  }

  async getRewardAmount(poolId, account) {
    const amount = await this.farmContract.methods.getRewardAmount(poolId, account).call();
    return FixedNumber.from(`${bnHelper.fromDecimals(amount, DECIMALS)}`);
  }

  async getUserStakeBalance(poolId, account) {
    const amount = await this.farmContract.methods.getUserStakeBalance(poolId, account).call();
    return FixedNumber.from(`${bnHelper.fromDecimals(amount, DECIMALS)}`);
  }

  async getTotalLockedAmount(poolId) {
    const { 4: amount } = await this.farmContract.methods.poolInfo(poolId).call();
    return FixedNumber.from(`${bnHelper.fromDecimals(amount, DECIMALS)}`);
  }
  async getUserLength(poolId) {
    const userLength = await this.farmContract.methods.getPoolUserLength(poolId).call();
    return userLength;
  }

  async getStakeApy() {
    const totalLockedAmount = await this.getTotalLockedAmount(0);

    const apr = totalLockedAmount.isZero()
      ? Zero
      : this.tokenPerSecond
          .mulUnsafe(SENCOND_PER_YEAR)
          .divUnsafe(this.totalAllocPoint)
          .mulUnsafe(this.tokenPoolInfo!.allocPoint)
          .divUnsafe(totalLockedAmount)
          .mulUnsafe(FixedNumber.from("100"));

    return apr;
  }
}
