import { FixedNumber } from "@ethersproject/bignumber";
import {
  ChainId,
  Token,
  Fetcher,
  Route,
  Trade,
  TokenAmount,
  TradeType,
  Pair,
} from "@overage69/pancake-sdk-v2";
import { getDefaultProvider } from "@ethersproject/providers";
import Web3 from "web3";
const provider = getDefaultProvider("https://bsc-dataseed1.binance.org");
const web3 = new Web3("https://bsc-dataseed1.binance.org");
const addresses = {
  WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
};
const [WBNB, BUSD] = [addresses.WBNB, addresses.BUSD].map(
  (tokenAddress) => new Token(ChainId.MAINNET, tokenAddress, 18)
);
const TOKEN_ADDRESS = "0xc60dEe4852eE6190CC440e87fC06796eC5ed4bB0";

export class PancakePriceHelper {
  tokenPriceBUSD?: any;

  async getTokenPrice() {
    const token = this.createNewBep20Token(TOKEN_ADDRESS, 18);
    //this.tokenPriceBUSD = '0.01';
    this.tokenPriceBUSD = await this.getBep20TokenPriceBUSD(token);
    // console.log(this.tokenPriceBUSD)
  }

  createNewBep20Token(tokenAddress, tokenDecimals) {
    return new Token(ChainId.MAINNET, tokenAddress, tokenDecimals);
  }

  async getBep20TokenPriceBUSD(token) {
    const pair = await Fetcher.fetchPairData(token, WBNB, provider);
    const route = new Route([pair], token);
    const amount = new TokenAmount(token, web3.utils.toBN(Math.pow(10, token.decimals)) as any);
    const trade = new Trade(route, amount, TradeType.EXACT_INPUT);
    const pair1 = await Fetcher.fetchPairData(WBNB, BUSD, provider);
    const route1 = new Route([pair1], WBNB);
    const amount1 = new TokenAmount(WBNB, web3.utils.toBN(Math.pow(10, token.decimals)) as any);
    const trade1 = new Trade(route1, amount1, TradeType.EXACT_INPUT);
    return (Number(trade.executionPrice.toSignificant(18))*Number(trade1.executionPrice.toSignificant(18))).toFixed(9).toString();
  }
}
export const priceHelper = new PancakePriceHelper();
