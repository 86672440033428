








































































































































































































import { StakingViewModel } from "../viewmodels/staking-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
  components: {
    StakingDialog: () => import("../dialogs/staking-dialog.vue"),
    IncreaseStakeDialog: () => import("../dialogs/increase-stake-dialog.vue"),
    UnstakeDialog: () => import("../dialogs/unstake-dialog.vue"),
    HarvestDialog: () => import("../dialogs/harvest-dialog.vue"),
    FirstStake: () => import("../components/first-stake.vue"),
    Timeline: () => import("../components/timeline.vue"),
    TimelineItem: () => import("../components/timeline-item.vue"),
  },
})
export default class Staking extends Vue {
  @Provide() vm = new StakingViewModel();

  currentTicket = 0;
  isAnimating = false;
  walletStore = walletStore;
  screenWidth = window.innerWidth;

  destroyed() {
    this.vm.destroy();
  }
  stake(val: any) {
    console.log(val);
  }
}
