import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper, bnHelper } from "@/helpers/bignumber-helper";
import { StakingHandler } from "@/helpers/staking-handler";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { priceHelper } from "../../../helpers/pancakePriceHelper";
import { loadingController } from "@/components/global-loading/global-loading-controller";

export class StakingViewModel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable isDialogLoading = false;
  @observable isFirstLoad = false;
  @observable tokenPrice = FixedNumber.from("0");

  @observable stakeDialog = false;
  @observable increaseStakeDialog = false;
  @observable unstakeDialog = false;
  @observable tokenInputAmount = "";
  @observable tokenStakeAmount = Zero;
  @observable annualPercentageRate = FixedNumber.from("0");
  @observable rewardAmount = FixedNumber.from("0");
  @observable apr = Zero;
  @observable userLength = 0;

  @observable approved = false;
  @observable allowance = 0;
  @observable approving = false;
  @observable harvesting = false;
  @observable isShowHarvestDialog = false;

  @observable lockDuration = moment.duration(0, "second"); // in seconds
  @observable lockedUntil = moment().add(this.lockDuration);
  @observable stakeIncreaseLockedUntil = moment().add(this.lockDuration);

  @observable userStakedAmount = FixedNumber.from("0");
  @observable lastStakeTime: moment.Moment | null = null;
  @observable totalLockedAmount = FixedNumber.from("0");
  @observable totalValueLocked = FixedNumber.from("0");
  @observable dataLoading = false;

  stakingHandler?: StakingHandler;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.stakingHandler?.injectMetamask(walletStore.web3!);
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  async loadData() {
    const stakingHandler = new StakingHandler();
    this.stakingHandler = stakingHandler;
    this.dataLoading = true;

    try {
      await Promise.all([stakingHandler.load(), this.getTokenPrice()]);
      this.getUserLength();
      this.getApr();
      this.lockDuration = this.stakingHandler.tokenPoolInfo!.lockDuration!;
    } catch (error) {
      console.error("loadData", error);
    }

    timer(0, 20000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        const lockedAmount = await stakingHandler.getTotalLockedAmount(0);

        runInAction(() => {
          this.totalLockedAmount = lockedAmount;
          this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
        });
      });

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            stakingHandler.injectMetamask(walletStore.web3!);
            stakingHandler
              .approved(walletStore.account)
              .then((approved) => runInAction(() => {
                this.allowance = approved
                if (approved > 0) this.approved = true
              }));

            timer(0, 10000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.getApr();
                this.getUserLength();

                this.fetchPoolInfo();
              });
          }
        }
      )
    );
    this.dataLoading = false;
  }

  @asyncAction *fetchPoolInfo() {
    if (!walletStore.account || !this.stakingHandler) {
      this.userStakedAmount = FixedNumber.from("0");
    } else {
      this.getUserLastStaleTime();
      this.getUserRewardAmount();
      const lockedAmount = yield this.stakingHandler.getTotalLockedAmount(0);
      this.totalLockedAmount = lockedAmount;
      priceHelper.getTokenPrice().then((p) => {
        runInAction(() => (this.tokenPrice = priceHelper.tokenPriceBUSD));
      });
      this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
      this.userStakedAmount = yield this.stakingHandler.getUserStakeBalance(0, walletStore.account);

      if (!this.isFirstLoad) this.isFirstLoad = true;
    }
    this.dataLoading = false;
  }

  @asyncAction *getApr() {
    const apr = yield this.stakingHandler!.getStakeApy();
    this.apr = apr;
  }

  @asyncAction *getUserLastStaleTime() {
    const { lastStakeTime } = yield this.stakingHandler?.getUserInfo(0, walletStore.account);
    this.lastStakeTime = lastStakeTime;
  }

  @asyncAction *approve() {
    this.approving = true;
    try {
      yield this.stakingHandler!.approve(walletStore.account);
      //this.approved = true;
      this.stakingHandler!
        .approved(walletStore.account)
        .then((approved) => runInAction(() => {
          this.allowance = approved
          if (approved > 0) this.approved = true
        }));
    } catch (error) {
      const er = error as any;
      snackController.error(er.message);
    }
    this.approving = false;
  }

  @action.bound changeTokenInputAmount(amount) {
    this.tokenInputAmount = amount;
  }

  @action.bound maxSbBalance() {
    this.tokenInputAmount = walletStore.sbBalance.toString();
  }

  @action.bound requestStakeLP() {
    this.lockedUntil = moment().add(this.lockDuration);
    this.tokenStakeAmount = FixedNumber.from(this.tokenInputAmount);
    this.stakeDialog = true;
  }

  @action.bound increaseStakeToken() {
    this.stakeIncreaseLockedUntil = moment().add(this.lockDuration);
    this.tokenInputAmount = "";
    this.increaseStakeDialog = true;
  }

  @action.bound requestUnstakeLP() {
    this.unstakeDialog = true;
  }

  @asyncAction *getTokenPrice() {
    yield priceHelper.getTokenPrice();
    this.tokenPrice = priceHelper.tokenPriceBUSD;

  }

  @asyncAction *confirmStake() {
    this.isDialogLoading = true;
    this.tokenStakeAmount = FixedNumber.from(this.tokenInputAmount);
    //console.log(parseFloat(this.tokenInputAmount) > this.allowance/1000000000000000000,this.tokenInputAmount,this.allowance/1000000000000000000)
    try {
      if (!this.approve || (parseFloat(this.tokenInputAmount) > 0 && this.validDialogInputAmount && parseFloat(this.tokenInputAmount) > this.allowance/1000000000000000000)) {
        console.log(222)
        yield this.stakingHandler!.approve(walletStore.account);
        yield this.stakingHandler!
          .approved(walletStore.account)
          .then((approved) => runInAction(() => {
            this.allowance = approved
            if (approved > 0) this.approved = true
          }));
      }
      if (this.approved && this.validDialogInputAmount && parseFloat(this.tokenInputAmount) <= this.allowance/1000000000000000000) {
        yield this.stakingHandler!.stake(0, walletStore.account, this.tokenStakeAmount);
        snackController.success("Stake LCEO successfully!");
        this.fetchPoolInfo();
        this.getUserLastStaleTime();
        yield this.stakingHandler!.getTokenPoolInfo();
        this.stakeDialog = false;
        this.increaseStakeDialog = false;
      }else{
        snackController.error("amount approved must be greater than or equal to amount staking..");
      }

    } catch (err) {
      const er = err as any;
      snackController.error(er.message);
      console.error(err);
    } finally {
      this.isDialogLoading = false;
    }
  }

  @asyncAction *harvest() {
    this.harvesting = true;
    try {
      yield this.stakingHandler?.harvest(0, walletStore.account);
      this.fetchPoolInfo();
      snackController.success("Harvest successfully!");
      this.isShowHarvestDialog = false;
    } catch (error) {
      const er = error as any;
      snackController.error(er.message);
    } finally {
      this.harvesting = false;
    }
  }

  @asyncAction *getUserRewardAmount() {
    const rewardAmount = yield this.stakingHandler?.getRewardAmount(0, walletStore.account);
    this.rewardAmount = rewardAmount;
  }
  @asyncAction *getUserLength() {
    const userLength = yield this.stakingHandler?.getUserLength(0);
    this.userLength = userLength;
  }

  @asyncAction *confirmUnstake() {
    this.isDialogLoading = true;
    try {
      yield this.stakingHandler!.unstake(0, walletStore.account);
      snackController.success("Unstake successfully");
      this.fetchPoolInfo();
      this.unstakeDialog = false;
    } catch (err) {
      const er = err as any;
      snackController.error(er.message);
    } finally {
      this.isDialogLoading = false;
    }
  }

  @action.bound cancelStakeDialog() {
    this.stakeDialog = false;
  }
  @action.bound cancelIncreaseStakeDialog() {
    this.increaseStakeDialog = false;
  }
  @action.bound cancelUnstakeDialog() {
    this.unstakeDialog = false;
  }
  @action.bound changeHarvestDialog(val) {
    this.isShowHarvestDialog = val;
  }

  @computed get isStaked() {
    return !!this.lastStakeTime;
  }
  @computed get userStakedValue() {
    return this.userStakedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
  }
  @computed get isStakingToken() {
    return bnHelper.gt(this.userStakedAmount, Zero);
  }

  @computed get lockInDays() {
    return this.lockDuration.asDays();
  }
  @computed get lockInSeconds() {
    return this.lockDuration.asSeconds();
  }
  @computed get unstakeTime() {
    return this.lastStakeTime?.clone().add(this.lockDuration);
  }
  @computed get canUnstake() {
    return bnHelper.gt(this.userStakedAmount, Zero);
  }

  @computed get canHarvest() {
    return bigNumberHelper.gt(this.rewardAmount, Zero);
  }

  @computed get validDialogInputAmount() {
    try {
      const amount = FixedNumber.from(this.tokenInputAmount);
      // if (amount > FixedNumber.from(this.allowance / 1000000000000000000)) {
      //   return false
      // }
      return bigNumberHelper.lte(amount, walletStore.sbBalance);
    } catch (error) {
      return false;
    }
  }

  @computed get totalStaked() {
    try {
      return this.userStakedAmount.addUnsafe(FixedNumber.from(this.tokenInputAmount));
    } catch (error) {
      return this.userStakedAmount;
    }
  }
  @computed get estimatedReward() {
    try {
      return this.totalStaked.mulUnsafe(this.apr.divUnsafe(FixedNumber.from("100")));
    } catch (error) {
      return Zero;
    }
  }
}
